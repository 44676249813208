export default () => ({
    evaluations: null,
    currentEvaluation: null,
    evaluationStudent: null,
    comments: null,
    ratings: null,
    students: null,
    ratingsTutorship: null,
    totalTutorship: null,
})
