
export default {
    
    name: 'evaluaciones',
    component: () => import(/* webpackChunkName: "Evaluacion" */ '@/modules/tutorship/layouts/TutorshipLayout.vue'),
    children: [
        {
            path: '',
            name: 'evaluation.home',
            component: () => import(/* webpackChunkName: "Evaluacion-listar" */ '@/modules/tutorship/views/coordinator/evaluation/List.vue'),
        },
    ],

}