import tutorApi from "@/api/tutorApi"

export const obtainPeriodsCreate = async ( { commit } ) => {
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.get(`/api/periodos`)

        if (data.ok) {
            commit('setPeriods', data.periodos)
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }

    } catch (error) {
        return { ok: false, message: error/* .response.data.error.message */ }
    }

}

export const showPeriod = async ( { commit }, { idPeriod } ) => {
    try {
        const { data } = await tutorApi.get(`/api/periodos/${idPeriod.id}`)

        if (data.ok) {
            commit('setCurrentPeriod', data.periodo)
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }

    } catch (error) {
        return { ok: false, message: error }
    }
}

export const createPeriod = async ( _, { form } ) => {
    const periodForm = {
        fechaInicial: form.dateMin.getFullYear()+"-"+(form.dateMin.getMonth()+1)+"-"+form.dateMin.getDate(),
        fechaFinal: form.dateMax.getFullYear()+"-"+(form.dateMax.getMonth()+1)+"-"+form.dateMax.getDate(),
        nombre: form.name,
    }
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.post(`/api/periodos`, periodForm)
        if (data.ok) {
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
} 

export const editPeriod = async ( _, { form } ) => {
    const periodForm = {
        fechaInicial: form.dateMin.getFullYear()+"-"+(form.dateMin.getMonth()+1)+"-"+form.dateMin.getDate(),
        fechaFinal: form.dateMax.getFullYear()+"-"+(form.dateMax.getMonth()+1)+"-"+form.dateMax.getDate(),
        nombre: form.name,
        estado: form.state
    }
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.put(`/api/periodos/${form.id}`, periodForm)
        if (data.ok) {
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
} 

export const deletePeriod = async ( _, idPeriod ) => {
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.delete(`/api/periodos/${idPeriod}`)
        if (data.ok) {
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
}