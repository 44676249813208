export const setEvaluations = ( state, evaluations ) => {
    state.evaluations = evaluations
}

export const setCurrentEvaluation = ( state, evaluation ) => {
    state.currentEvaluation = evaluation
}

export const setEvaluationUser = ( state, evaluation ) => {
    state.evaluationStudent = evaluation
}

export const setComments = ( state, comments ) => {
    state.comments = comments
}

export const setRatings = ( state, ratings ) => {
    state.ratings = ratings
}

export const setRatingsTutorship = ( state, ratingsTutorship ) => {
    state.ratingsTutorship = ratingsTutorship
} 

export const setTotalTutorship = ( state, totales ) => {
    state.totalTutorship = totales
}

export const setStudents = ( state, students ) => {
    state.students = students
}