
export default {
    
    name: 'temas',
    component: () => import(/* webpackChunkName: "Temas" */ '@/modules/tutorship/layouts/TutorshipLayout.vue'),
    children: [
        {
            path: '',
            name: 'theme.home',
            component: () => import(/* webpackChunkName: "Temas-listar" */ '@/modules/tutorship/views/coordinator/theme/List.vue'),
        },
    ],

}