
export default {
    
    name: 'tutorship',
    component: () => import(/* webpackChunkName: "Tutorship" */ '@/modules/tutorship/layouts/TutorshipLayout.vue'),
    children: [
        {
            path: '',
            name: 'tutorship.home',
            component: () => import(/* webpackChunkName: "Tutorship-home" */ '@/modules/tutorship/views/Home.vue'),
        },
        {
            path: 'available',
            name: 'tutorship.available',
            component: () => import(/* webpackChunkName: "Tutorship-available" */ '@/modules/tutorship/views/student/tutorships/Available.vue'),
        },
        {
            path: 'available/:id',
            name: 'tutorship.available.view',
            component: () => import(/* webpackChunkName: "Tutorship-available-view" */ '@/modules/tutorship/views/student/tutorships/View.vue'),
            props: ( route ) => {
                return {
                    id: Number(route.params.id)
                }
            }
        },
        {
            path: 'pending',
            name: 'tutorship.pending',
            component: () => import(/* webpackChunkName: "Tutorship-pending" */ '@/modules/tutorship/views/student/tutorships/Pending.vue'),
        },
        {
            path: 'pending/:id',
            name: 'tutorship.pending.view',
            component: () => import(/* webpackChunkName: "Tutorship-pending-view" */ '@/modules/tutorship/views/student/tutorships/View.vue'),
            props: ( route ) => {
                return {
                    id: Number(route.params.id)
                }
            }
        },
        {
            path: 'registered',
            name: 'tutorship.registered',
            component: () => import(/* webpackChunkName: "Tutorship-available" */ '@/modules/tutorship/views/student/tutorships/Registered.vue'),
        },
        {
            path: 'registered/:id',
            name: 'tutorship.registered.view',
            component: () => import(/* webpackChunkName: "Tutorship-registered-view" */ '@/modules/tutorship/views/student/tutorships/View.vue'),
            props: ( route ) => {
                return {
                    id: Number(route.params.id)
                }
            }
        },
        {
            path: 'old',
            name: 'tutorship.old',
            component: () => import(/* webpackChunkName: "Tutorship-available" */ '@/modules/tutorship/views/student/tutorships/Old.vue'),
        },
        {
            path: 'old/:id',
            name: 'tutorship.old.view',
            component: () => import(/* webpackChunkName: "Tutorship-old-view" */ '@/modules/tutorship/views/student/tutorships/View.vue'),
            props: ( route ) => {
                return {
                    id: Number(route.params.id)
                }
            }
        },
        {
            path: 'rejected',
            name: 'tutorship.rejected',
            component: () => import(/* webpackChunkName: "Tutorship-available" */ '@/modules/tutorship/views/student/tutorships/Rejected.vue'),
        },
        {
            path: 'free',
            name: 'tutorship.free',
            component: () => import(/* webpackChunkName: "Tutorship-tutor-free" */ '@/modules/tutorship/views/tutor/tutorship/Free.vue'),
        },
        {
            path: 'free/:id',
            name: 'tutorship.free.view',
            component: () => import(/* webpackChunkName: "Tutorship-free-view" */ '@/modules/tutorship/views/tutor/tutorship/View.vue'),
            props: ( route ) => {
                return {
                    id: Number(route.params.id)
                }
            }
        },
        {
            path: 'free/show/:id',
            name: 'tutorship.free.show',
            component: () => import(/* webpackChunkName: "Tutorship-tutor-free" */'@/modules/tutorship/views/tutor/tutorship/free/Show.vue'),
            props: ( route ) => {
                return {
                    id: Number(route.params.id)
                }
            }
        },
        {
            path: 'assigned',
            name: 'tutorship.assigned',
            component: () => import(/* webpackChunkName: "Tutorship-tutor-assigned" */ '@/modules/tutorship/views/tutor/tutorship/Assigned.vue'),
        },
        {
            path: 'assigned/:id',
            name: 'tutorship.assigned.view',
            component: () => import(/* webpackChunkName: "Tutorship-assigned-view" */ '@/modules/tutorship/views/tutor/tutorship/View.vue'),
            props: ( route ) => {
                return {
                    id: Number(route.params.id)
                }
            }
        },
        {
            path: 'completed',
            name: 'tutorship.completed',
            component: () => import(/* webpackChunkName: "Tutorship-tutor-completed" */ '@/modules/tutorship/views/tutor/tutorship/Completed.vue'),
        },
        {
            path: 'completed/:id',
            name: 'tutorship.completed.view',
            component: () => import(/* webpackChunkName: "Tutorship-completed-view" */ '@/modules/tutorship/views/tutor/tutorship/View.vue'),
            props: ( route ) => {
                return {
                    id: Number(route.params.id)
                }
            }
        },
        {
            path: 'created',
            name: 'tutorship.created',
            component: () => import(/* webpackChunkName: "Tutorship-coordinator-created" */ '@/modules/tutorship/views/coordinator/tutorship/Created.vue'),
        },
        {
            path: 'created/new',
            name:'tutorship.created.new',
            component: () => import(/* webpackChunkName: "Tutorship-coordinator-created" */ '@/modules/tutorship/views/coordinator/tutorship/New.vue'),
        },
        {
            path: 'created/show/:id',
            name: 'tutorship.created.show',
            component: () => import(/* webpackChunkName: "Tutorship-coordinator-show" */'@/modules/tutorship/views/coordinator/tutorship/View.vue'),
            props: ( route ) => {
                return {
                    id: Number(route.params.id)
                }
            }
        },
        {
            path: 'edit/:id',
            name: 'tutorship.edit',
            component: () => import(/* webpackChunkName: "Tutorship-coordinator-edit" */ '@/modules/tutorship/views/coordinator/tutorship/Edit.vue'),
            props: ( route ) => {
                return {
                    id: Number(route.params.id)
                }
            }
        },
        {
            path: 'done',
            name: 'tutorship.done',
            component: () => import(/* webpackChunkName: "Tutorship-coordinator-done" */ '@/modules/tutorship/views/coordinator/tutorship/Done.vue'),
        },
        {
            path: 'reports',
            name: 'tutorship.reports',
            component: () => import(/* webpackChunkName: "Tutorship-coordinator-reports" */ '@/modules/tutorship/views/coordinator/tutorship/Reports.vue'),
        },
        {
            path: 'help',
            name: 'tutorship.help',
        }
        // {
        //     path: ':id',
        //     name: 'entry',
        //     component: () => import(/* webpackChunkName: "Daybook-entry" */ '@/modules/daybook/views/EntryView.vue'),
        //     props: ( route ) => {
        //         return {
        //             id: route.params.id
        //         }
        //     }
        // }
    ],
}