export default {
    
    name: 'error',
    component: () => import(/* webpackChunkName: "Tutorship" */ '@/modules/tutorship/layouts/TutorshipLayout.vue'),
    children: [
        {
            path: '',
            name: 'tutorship.error',
            component: () => import(/* webpackChunkName: "Tutorship-error" */ '@/modules/tutorship/views/Error.vue'),
        },
    ],

}