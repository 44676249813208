import { createStore } from 'vuex'

import general from './general'
import auth from '../modules/auth/store'
import tutorship from '../modules/tutorship/store/tutorship'
import theme from '../modules/tutorship/store/theme'
import evaluation from '../modules/tutorship/store/evaluation'
import observation from '../modules/tutorship/store/observation'
import period from '../modules/tutorship/store/period'
import user from '../modules/tutorship/store/user'

const store = createStore({
    modules: {
        general,
        auth,
        tutorship,
        theme,
        evaluation,
        observation,
        period,
        user
    }
})

export default store
