
export default {
    
    name: 'observaciones',
    component: () => import(/* webpackChunkName: "Observaciones" */ '@/modules/tutorship/layouts/TutorshipLayout.vue'),
    children: [
        {
            path: '',
            name: 'observation.home',
            component: () => import(/* webpackChunkName: "Observaciones-listar" */ '@/modules/tutorship/views/coordinator/observation/List.vue'),
        },
    ],

}