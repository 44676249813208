
import tutorApi from "@/api/tutorApi"
// export const myAction = async ( { commit } ) => {

// }

export const obtainTutors = async ( { commit } ) => {
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.get(`/api/coordinador/tutorias/tutores`)
        if (data.ok) {
            commit('setTutors', data.tutores)
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }

    } catch (error) {
        return { ok: false, message: error }
    }
}

export const obtainNextTutorshipsCoor = async ( { commit } ) => {
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.get(`/api/coordinador/tutorias/proximas`)
        if (data.ok) {
            commit('setTutorships', data.tutorias)
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }

    } catch (error) {
        return { ok: false, message: error }
    }
}

export const obtainNextTutorshipsStudent = async ( { commit }, { id } ) => {
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.get(`/api/estudiante/tutorias/proximas/${id}`)
        if (data.ok) {
            commit('setTutorships', data.tutorias)
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }

    } catch (error) {
        return { ok: false, message: error }
    }
}

export const obtainNextTutorshipsTutor = async ( { commit }, { id } ) => {
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.get(`/api/tutor/tutorias/proximas/${id}`)
        if (data.ok) {
            commit('setTutorships', data.tutorias)
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }

    } catch (error) {
        return { ok: false, message: error }
    }
}

export const obtainTutorshipsCreated = async ( { commit } ) => {
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.get(`/api/coordinador/tutorias/creadas`)
        if (data.ok) {
            commit('setTutorships', data.tutorias)
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }

    } catch (error) {
        return { ok: false, message: error }
    }
}

export const obtainTutorshipsDone = async ( { commit } ) => {
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.get(`/api/coordinador/tutorias/realizadas`)
        if (data.ok) {
            commit('setTutorships', data.tutorias)
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }

    } catch (error) {
        return { ok: false, message: error }
    }
}

export const showTutorship = async ( { commit }, { idTutorship } ) => {
    try {
        const { data } = await tutorApi.get(`/api/tutorias/${idTutorship}`)
        commit('setCurrentTutorship', data["tutoria"])
        return { ok: true, message: 'Tutorias creadas obtenidas correctamente.' }
    } catch (error) {
        return { ok: false, message: error }
    }
}

export const createTutorship = async ( _, { form }) => {
    const tutorshipForm = {
        nombre: form.nameTutorship,
        tutor_id: form.selectedTutor.code,
        fechaInicial: form.startTime.getFullYear()+"-"+(form.startTime.getMonth()+1)+"-"+form.startTime.getDate(),
        fechaFinal: form.endTime.getFullYear()+"-"+(form.endTime.getMonth()+1)+"-"+form.endTime.getDate(),
        formulario: form.formLink == '' ? null : form.formLink,
        linkEncuentro: form.meetingLink == '' ? null : form.meetingLink,
        recursosExternos: form.resourceLink == '' ? null : form.resourceLink,
        userMax: form.numMaxStudents,
        userMin: form.numMinStudents,
        estado: form.selectedTutor.code == null ? 3 : form.selectedState,
        numTotalHoras: form.numTotalHours,
        editable: form.editableCheck ? true : false,
        temas: form.themesCheck ? true : false,
        descripcion: form.description
    }
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.post(`/api/tutorias`, tutorshipForm)
        if (data.ok) {
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
}

export const editTutorship = async ( _, { id, form }) => {
    const tutorshipForm = {
        nombre: form.nameTutorship,
        tutor_id: form.selectedTutor.code,
        fechaInicial: form.startTime.getFullYear()+"-"+(form.startTime.getMonth()+1)+"-"+form.startTime.getDate(),
        fechaFinal: form.endTime.getFullYear()+"-"+(form.endTime.getMonth()+1)+"-"+form.endTime.getDate(),
        formulario: form.formLink == '' ? null : form.formLink,
        linkEncuentro: form.meetingLink == '' ? null : form.meetingLink,
        recursosExternos: form.resourceLink == '' ? null : form.resourceLink,
        userMax: form.numMaxStudents,
        userMin: form.numMinStudents,
        numTotalHoras: form.numTotalHours,
        editable: form.editableCheck ? true : false,
        temas: form.themesCheck ? true : false,
        descripcion: form.description
    }
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.put(`/api/tutorias/${id}`, tutorshipForm)
        if (data.ok) {
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
}

export const deleteTutorships = async ( {commit} , idTutoria ) => {
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.delete(`/api/eliminarTutorias/${idTutoria}`)
        if (data.ok) {
            commit('deleteTutorships', idTutoria)
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
}

export const createMeeting = async ( _, { role, form, idTutoria } ) => {
    const meetingsForm = {
        fecha: form.date.getFullYear()+"-"+(form.date.getMonth()+1)+"-"+form.date.getDate(),
        horaInicial: form.hourMin.getHours()+':'+form.hourMin.getMinutes()+':'+form.hourMin.getSeconds(),
        horaFinal: form.hourMax.getHours()+':'+form.hourMax.getMinutes()+':'+form.hourMax.getSeconds(),
        descripcion: form.description,
        tutoria_id: idTutoria,
        temas: form.themes,
        recursos: (form.resource == null || form.resource == ' ' || form.resource == '') ? null : form.resource,
    }
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        /* const { data } = */ await tutorApi.post(`/api/crearEncuentros/${role}`, meetingsForm)
        return { ok: true, message: 'Encuentro creado correctamente.' }
    } catch (error) {
        console.log(error);
    }
} 


// COORDINATOR

export const obtainTutorshipCoordinator = async ( { commit }, id ) => {
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.get(`/api/coordinador/tutorias/obtener/${id}`)
        if (data.ok) {
            commit('setCurrentTutorship', data.tutoria)
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
}

export const saveMeetings = async ( { commit }, form ) => {
    const meetingForm = {
        fecha: form.date.getFullYear()+"-"+(form.date.getMonth()+1)+"-"+form.date.getDate(),
        horaInicial: form.startTime.getHours()+':'+form.startTime.getMinutes(),
        horaFinal: form.finalTime.getHours()+':'+form.finalTime.getMinutes(),
        descripcion: form.description,
        tutoria_id: form.idTutoria,
        temas: form.themes,
        recursos: (form.resource == null || form.resource == ' ' || form.resource == '') ? null : form.resource,
    }
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.post(`/api/encuentros`, meetingForm)
        if (data.ok) {
            commit('addMeeting', data.encuentro)
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
} 

export const editMeetings = async ( { commit }, form ) => {
    const meetingForm = {
        fecha: form.date.getFullYear()+"-"+(form.date.getMonth()+1)+"-"+form.date.getDate(),
        horaInicial: form.startTime.getHours()+':'+form.startTime.getMinutes(),
        horaFinal: form.finalTime.getHours()+':'+form.finalTime.getMinutes(),
        descripcion: form.description,
        tutoria_id: form.idTutoria,
        temas: form.themes,
        estadoTutoria: form.stateTutorship,
        recursos: (form.resource == null || form.resource == ' ' || form.resource == '') ? null : form.resource,
    }
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.put(`/api/encuentros/${form.idMeeting}`, meetingForm)
        if (data.ok) {
            commit('editMeeting', data.encuentro)
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
} 

export const copyMeetings = async ( { commit }, id ) => {
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.post(`/api/encuentros/copiar/${id}`)
        if (data.ok) {
            commit('copyMeetings', { id, meeting: data.encuentro })
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
}

export const deleteMeetings = async ( { commit }, id ) => {
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.delete(`/api/encuentros/${id}`)
        if (data.ok) {
            commit('deleteMeetings', id)
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
}

export const obtainPostulation = async ( { commit }, id ) => {
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.get(`/api/tutorias/postulados/${id}`)
        if (data.ok) {
            commit('setTutors', data.tutores)
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
}

export const approvePostulation = async ( { commit }, id ) => {
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.put(`/api/tutorias/postulacion/avalar/${id}`)
        if (data.ok) {
            commit('approvePostulation', data.postulacion)
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
}

export const disapprovePostulation = async ( { commit }, form ) => {
    const postulationForm = {
        observacion: form.observation
    }
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.put(`/api/tutorias/postulacion/rechazar/${form.id}`, postulationForm)
        if (data.ok) {
            commit('disapprovePostulation', data.postulacion)
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
}

export const disapproveStudents = async ( { commit }, form ) => {
    const inscriptionForm = {
        observacion: form.observation
    }
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.put(`/api/tutorias/inscripcion/cancelar/${form.id}`, inscriptionForm)
        if (data.ok) {
            commit('disapproveStudents', data.inscripcion)
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
}

export const copyTutorships = async ( _, selectedDuplicate ) => {
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.post(`/api/tutorias/copiar/${selectedDuplicate.id}`, selectedDuplicate)
        if (data.ok) {
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
}

export const obtainInscribed = async ( { commit }, id ) => {
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.get(`/api/tutorias/inscritos/${id}`)
        if (data.ok) {
            commit('setStudents', data.estudiantes)
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
}

export const approveInscription = async ( { commit }, id ) => {
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.put(`/api/tutorias/inscripcion/avalar/${id}`)
        if (data.ok) {
            commit('approveInscription', data.inscripcion)
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
}

export const obtainObservationsTutor = async ( { commit }, id ) => {
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.get(`/api/tutorias/postulacion/observaciones/${id}`)
        if (data.ok) {
            commit('setObservations', data.observaciones)
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
}

export const startTutorship = async ( { commit }, id ) => {
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.put(`/api/tutorias/iniciar/${id}`)
        if (data.ok) {
            commit('startTutorship')
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
}

export const activateTutorship = async ( { commit }, id ) => {
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.put(`/api/tutorias/activar/${id}`)
        if (data.ok) {
            commit('activateTutorship')
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
}

export const activeValuate = async ( _, state ) => {
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.put(`/api/evaluacion`, state)
        if (data.ok) {
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
}

export const deactivateTutorship = async ( { commit }, id ) => {
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.put(`/api/tutorias/desactivar/${id}`)
        if (data.ok) {
            commit('deactivateTutorship')
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
}

export const finalizeTutorship = async ( { commit }, id ) => {
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.put(`/api/tutorias/finalizar/${id}`)
        if (data.ok) {
            commit('finalizeTutorship')
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
}

export const reviseTutorship = async ( { commit }, id ) => {
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.put(`/api/tutorias/revisar/${id}`)
        if (data.ok) {
            commit('reviseTutorship')
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
}

export const requestReviewTutorship = async ( { commit }, id ) => {
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.put(`/api/tutor/tutorias/pedirRevision/${id}`)
        if (data.ok) {
            commit('requestReviewTutorship')
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
}

// TUTOR

export const obtainTutorshipsFree = async ( { commit/* , rootState */ } ) => {
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.get(`/api/tutor/tutorias/libres`)/* /${rootState.auth.user.id} */
        commit('setTutorships', data.tutorias)
        
        return { ok: true, message: 'Tutorias creadas obtenidas correctamente.' }

    } catch (error) {
        return { ok: false, message: error/* .response.data.error.message */ }
    }

}

export const obtainTutorshipsAssigned = async ( { commit, rootState } ) => {
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.get(`/api/tutor/tutorias/asignadas/${rootState.auth.user.id}`)/* /${rootState.auth.user.id} */
        commit('setTutorships', data.tutorias)
        
        return { ok: true, message: 'Tutorias creadas obtenidas correctamente.' }
    } catch (error) {
        return { ok: false, message: error/* .response.data.error.message */ }
    }
}

export const obtainTutorshipsCompleted = async ( { commit, rootState } ) => {
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.get(`/api/tutor/tutorias/finalizadas/${rootState.auth.user.id}`)
        commit('setTutorships', data.tutorias)
        
        return { ok: true, message: 'Tutorias creadas obtenidas correctamente.' }
    } catch (error) {
        return { ok: false, message: error/* .response.data.error.message */ }
    }
}

export const obtainTutorshipTutor = async ( { commit }, id ) => {
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.get(`/api/tutor/tutorias/${id}`)
        if (data.ok) {
            commit('setCurrentTutorship', data.tutoria)
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
}

export const postulateTutorship = async ( { commit }, id ) => {
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.put(`/api/tutor/postular/${id}`)
        if (data.ok) {
            commit('postulateTutorship', data.postulacion)
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
}

export const noPostulateTutorship = async ( { commit }, id ) => {
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.put(`/api/tutor/no_postular/${id}`)
        if (data.ok) {
            commit('noPostulateTutorship', id)
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
}

export const saveMeetingsTutor = async ( { commit }, form ) => {
    const meetingForm = {
        fecha: form.date.getFullYear()+"-"+(form.date.getMonth()+1)+"-"+form.date.getDate(),
        horaInicial: form.startTime.getHours()+':'+form.startTime.getMinutes(),
        horaFinal: form.finalTime.getHours()+':'+form.finalTime.getMinutes(),
        descripcion: form.description,
        tutoria_id: form.idTutoria,
        temas: form.themes,
        recursos: (form.resource == null || form.resource == ' ' || form.resource == '') ? null : form.resource,
    }
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.post(`/api/tutor/encuentros`, meetingForm)
        if (data.ok) {
            commit('addMeeting', data.encuentro)
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
} 

export const editMeetingsTutor = async ( { commit }, form ) => {
    const meetingForm = {
        fecha: form.date.getFullYear()+"-"+(form.date.getMonth()+1)+"-"+form.date.getDate(),
        horaInicial: form.startTime.getHours()+':'+form.startTime.getMinutes(),
        horaFinal: form.finalTime.getHours()+':'+form.finalTime.getMinutes(),
        descripcion: form.description,
        tutoria_id: form.idTutoria,
        temas: form.themes,
        estadoTutoria: form.stateTutorship,
        recursos: (form.resource == null || form.resource == ' ' || form.resource == '') ? null : form.resource,
    }
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.put(`/api/tutor/encuentros/${form.idMeeting}`, meetingForm)
        if (data.ok) {
            commit('editMeeting', data.encuentro)
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
} 

export const copyMeetingsTutor = async ( { commit }, id ) => {
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.post(`/api/tutor/encuentros/copiar/${id}`)
        if (data.ok) {
            commit('copyMeetings', { id, meeting: data.encuentro })
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
}

export const deleteMeetingsTutor = async ( { commit }, id ) => {
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.delete(`/api/tutor/encuentros/${id}`)
        if (data.ok) {
            commit('deleteMeetings', id)
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
}

export const obtainInscribedTutor = async ( { commit }, id ) => {
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.get(`/api/tutor/tutorias/inscritos/${id}`)
        if (data.ok) {
            commit('setStudents', data.estudiantes)
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
}

export const approveInscriptionTutor = async ( { commit }, id ) => {
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.put(`/api/tutor/tutorias/inscripcion/avalar/${id}`)
        if (data.ok) {
            commit('approveInscription', data.inscripcion)
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
}

export const disapproveStudentsTutor = async ( { commit }, form ) => {
    const inscriptionForm = {
        observacion: form.observation
    }
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.put(`/api/tutor/tutorias/inscripcion/cancelar/${form.id}`, inscriptionForm)
        if (data.ok) {
            commit('disapproveStudents', data.inscripcion)
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
}

export const editTutorshipTutor = async ( { commit }, { id, form }) => {
    const tutorshipForm = {
        formulario: (form.formLink == null || form.formLink == '' || form.formLink == ' ') ? null : form.formLink,
        linkEncuentro: (form.meetingLink == null || form.meetingLink == '' || form.meetingLink == ' ') ? null : form.meetingLink,
        recursosExternos: (form.resourceLink == null || form.resourceLink == '' || form.resourceLink == ' ') ? null : form.resourceLink,
    }
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.put(`/api/tutor/tutorias/${id}`, tutorshipForm)
        if (data.ok) {
            commit('editTutorshipTutor', tutorshipForm)
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
}


export const generateReportCordinator = async ( { commit }, form ) => {
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.post(`/api/reporte`, form)
        if (data.ok) {
            commit('setReport', data.reporte)
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
}

export const generateReportPdfCordinator = async ( _, form ) => {
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.get(`/api/reporte/pdf`, {responseType: 'blob', params: { form } })
        const downloadUrl = window.URL.createObjectURL(data)
        window.open(downloadUrl, '__blank')
        return { ok: true, message: 'Pdf Generado Correctamente' }

    } catch (error) {
        return { ok: false, message: error }
    }
}

export const obtainTutorshipMontly = async ( { commit } ) => {
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.get(`/api/reporte/tutoriasPorMes`)
        if (data.ok) {
            commit('setTutorshipMontly', data.tutorias)
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
}

// ESTUDIANTE

export const obtainTutorshipStudent = async ( { commit }, id ) => {
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.post(`/api/estudiante/tutorias/${id}`)
        if (data.ok) {
            commit('setCurrentTutorship', data.tutoria)
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
}

// DISPONIBLES

export const obtainTutorshipsAvailable = async ( { commit }, id ) => {
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.get(`/api/estudiante/tutorias/disponibles/${id}`)
        if (data.ok) {
            commit('setTutorships', data.tutorias)
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
}

export const SignOnTutorship = async ( { commit }, { idTutorship, idUser }) => {
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.post(`/api/estudiante/tutorias/${idTutorship}/${idUser}`)
        if (data.ok) {
            commit('signOnTutorship', data.tutoria)
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
}

// PENDIENTES

export const obtainTutorshipsPending = async ( { commit }, id ) => {
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.get(`/api/estudiante/tutorias/pendientes/${id}`)
        if (data.ok) {
            commit('setTutorships', data.tutorias)
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
}

export const removeTutorship = async ( { commit }, id) => {
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.delete(`/api/estudiante/tutorias/eliminar/${id}`)
        if (data.ok) {
            commit('removeTutorship')
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
}

//INSCRITAS

export const obtainRegisteredTutorship = async ( { commit }, id) => {
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.get(`/api/estudiante/tutorias/inscritas/${id}`)
        if (data.ok) {
            commit('setTutorships', data.tutorias)
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
}

export const cancelTutorship = async ( _, id) => {
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.put(`/api/estudiante/tutorias/cancelar/${id}`)
        if (data.ok) {
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
}

export const enableTutorship = async (_, id) => {
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.put(`/api/estudiante/tutorias/habilitar/${id}`)
        if (data.ok) {
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
}

// ANTIGUAS

export const obtainOldTutorship = async ( { commit }, id) => {
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.get(`/api/estudiante/tutorias/antiguas/${id}`)
        if (data.ok) {
            commit('setTutorships', data.tutorias)
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
}

// RECHAZADAS

export const obtainRejectedTutorship = async ( { commit }, id) => {
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.get(`/api/estudiante/tutorias/rechazadas/${id}`)
        if (data.ok) {
            commit('setTutorships', data.tutorias)
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
}