
// export const myAction = async ( { commit } ) => {

// }


export const toggleLoading = async ( { commit } ) => {
    commit('toggleLoading')
}

export const toggleModalRole = async ( { commit } ) => {
    commit('toggleModalRole')
}