
// export const myGetter = ( state ) => {
//   return
// }

export const getTutorships = ( state ) => {
    return state.tutorships
}

export const getCurrentTutorship = ( state ) => {
    return state.currentTutorship
}

export const getTutors = ( state ) => {
    return state.tutors
}

export const getObservations = ( state ) => {
    return state.observations
}

export const getStudents = ( state ) => {
    return state.students
}

export const getReport = ( state ) => {
    return state.report
}

export const getTutorshipMontly = ( state ) => {
    return state.tutorshipMontly
}