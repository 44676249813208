
import tutorApi from "@/api/tutorApi"
// export const myMutation = ( state ) => {

// }

export const loginUser = ( state, { user, token:idToken } ) => {

    if ( idToken ) {
        localStorage.setItem('idToken', idToken)
        tutorApi.defaults.headers.common["Authorization"] = "Bearer " + idToken
        state.idToken = idToken
    }

    state.user = user
    const roles = []
    user.roles.forEach(rol => {
        roles.push({
            name: rol,
            code: rol,
        })
    });
    state.roles = roles
    state.status = 'authenticated'

}

/* export const setMainRole = ( state, { mainRole } ) => {
    state.mainRole = mainRole
    localStorage.setItem('mainRole', mainRole)
} */

export const logout = ( state ) => {
    state.user = null
    state.idToken = null
    state.status = 'not-authenticated'
    /* state.mainRole = null */

    localStorage.removeItem('idToken')
    tutorApi.defaults.headers.common["Authorization"] = null
    /* localStorage.removeItem('mainRole') */
}

export const editCurrentUser = ( state, user ) => {
    state.user.apellidos = user.apellido
    state.user.curso = user.curso
    state.user.email = user.email
    state.user.genero = user.genero
    state.user.nombres = user.nombre
    state.user.programa_academico_id = user.programa_academico_id
    state.user.semestre = user.semestre
    state.user.telefono = user.telefono
    state.user.tipoCedula = user.tipoCedula
}