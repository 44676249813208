export const getObservationsPerformance = ( state ) => {
    return state.observationsPerformance
}

export const getCurrentObservationPerformance = ( state ) => {
    return state.currentObservationPerformance
}

export const getObservationsEntrance = ( state ) => {
    return state.observationsEntrance
}

export const getCurrentObservationEntrance = ( state ) => {
    return state.currentObservationEntrance
}

export const getObservationsTutorship = ( state ) => {
    return state.observationsTutorship
}

export const getCurrentObservationTutorship = ( state ) => {
    return state.currentObservationTutorship
}

export const getObservations = ( state ) => {
    return state.observations
}

export const getCurrentObservation = ( state ) => {
    return state.currentObservation
}

export const getObservationsStudent = ( state ) => {
    return state.observationsStudent
}

export const getObservationsTutor = ( state ) => {
    return state.observationsTutor
}

export const getValuateStudent = ( state ) => {
    return state.valuateStudent
}

export const getValuateTutor = ( state ) => {
    return state.valuateTutor
}

export const getNumTutorshipsTutor = ( state ) => {
    return state.numTutorshipsTutor
}

export const getNumTutorshipsStudent = ( state ) => {
    return state.numTutorshipsStudent
}