import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// Componentes externos
import Toast, { POSITION } from "vue-toastification"
import PrimeVue from 'primevue/config'
import ConfirmationService from 'primevue/confirmationservice'
import Tooltip from 'primevue/tooltip'

// CSS Componentes externos
import "vue-toastification/dist/index.css"
import "primevue/resources/themes/saga-green/theme.css"
import "primevue/resources/primevue.min.css"
import "primeicons/primeicons.css"

const options = {
    position: POSITION.BOTTOM_LEFT,
};

createApp(App)
    .use(store)
    .use(router)
    .use(Toast, options)
    .use(PrimeVue, {
        locale: {
            startsWith: "Comienza con",
            contains: "Contiene",
            notContains: "No contiene",
            endsWith: "Termina con",
            equals: "Es igual a",
            notEquals: "No es igual a",
            noFilter: "Sin filtro",
            lt: "Menor que",
            lte: "Menor o igual que",
            gt: "Mayor que",
            gte: "Mayor o igual que",
            dateIs: "La fecha es",
            dateIsNot: "La fecha no es",
            dateBefore: "La fecha es antes de",
            dateAfter: "La fecha es después de",
            clear: "Borrar",
            apply: "Aplicar",
            matchAll: "Coincidir todo",
            matchAny: "Coincidir con cualquiera",
            addRule: "Añadir regla",
            removeRule: "Eliminar regla",
            accept: "Si",
            reject: "No",
            choose: "Escoger",
            upload: "Subir/Cargar",
            cancel: "Cancelar",
            dayNames: [
            "Domingo",
            "Lunes",
            "Martes",
            "Miércoles",
            "Jueves",
            "Viernes",
            "Sábado",
            ],
            dayNamesShort: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"],
            dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
            monthNames: [
            "Enero",
            "Febrero",
            "Marzo",
            "Abril",
            "Mayo",
            "Junio",
            "Julio",
            "Agosto",
            "Septiembre",
            "Octubre",
            "Noviembre",
            "Diciembre",
            ],
            monthNamesShort: [
            "Ene",
            "Feb",
            "Mar",
            "Abr",
            "May",
            "Jun",
            "Jul",
            "Ago",
            "Sep",
            "Oct",
            "Nov",
            "Dic",
            ],
            today: "Hoy",
            weekHeader: "Semana",
            firstDayOfWeek: 0,
            dateFormat: "mm/dd/yy",
            weak: "Débil",
            medium: "Medio",
            strong: "Fuerte",
            passwordPrompt: "Introduza la contraseña",
            emptyFilterMessage: "No se han encontrado resultados",
            emptyMessage: "No hay opciones disponibles",
        },
    })
    .directive('tooltip', Tooltip)
    .use(ConfirmationService)
    .mount('#app')

