import tutorApi from "@/api/tutorApi"

//COORDINADOR

export const obtainThemes = async ( { commit }, id ) => {
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.get(`/api/temas/listar/${id}`)

        if (data.ok) {
            commit('setThemes', data.temas)
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }

    } catch (error) {
        return { ok: false, message: error/* .response.data.error.message */ }
    }

}


export const saveThemes = async ( { commit }, theme ) => {
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.post(`/api/temas`, { tema: theme.name } )

        if (data.ok) {
            commit('setThemes', data.temas)
            return { ok: true, message: data.message, themes: data.tema }
        } else {
            return { ok: false, message: data.message }
        }

    } catch (error) {
        return { ok: false, message: error/* .response.data.error.message */ }
    }

}

export const saveThemesMeeting = async ( { commit }, theme ) => {
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.post(`/api/temas/tutor`, { tema: theme.name } )

        if (data.ok) {
            commit('setThemes', data.temas)
            return { ok: true, message: data.message, themes: data.tema }
        } else {
            return { ok: false, message: data.message }
        }

    } catch (error) {
        return { ok: false, message: error/* .response.data.error.message */ }
    }

}


export const obtainThemesCreate = async ( { commit } ) => {
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.get(`/api/temas`)

        if (data.ok) {
            commit('setThemes', data.temas)
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }

    } catch (error) {
        return { ok: false, message: error/* .response.data.error.message */ }
    }

}

export const showTheme = async ( { commit }, { idTheme } ) => {
    try {
        const { data } = await tutorApi.get(`/api/temas/${idTheme}`)

        if (data.ok) {
            commit('setCurrentTheme', data.tema)
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }

    } catch (error) {
        return { ok: false, message: error }
    }
}

export const createTheme = async ( _, { form } ) => {
    const themeForm = {
        tema: form.name,
    }
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.post(`/api/temas`, themeForm)
        if (data.ok) {
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
} 

export const editTheme = async ( _, { form } ) => {
    const themeForm = {
        tema: form.name
    }
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.put(`/api/temas/${form.id}`, themeForm)
        if (data.ok) {
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
} 

export const deleteTheme = async ( _, idTheme ) => {
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.delete(`/api/temas/${idTheme}`)
        if (data.ok) {
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
}

//TUTOR
export const obtainThemesTutor = async ( { commit }, id ) => {
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.get(`/api/tutor/temas/${id}`)

        if (data.ok) {
            commit('setThemes', data.temas)
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }

    } catch (error) {
        return { ok: false, message: error/* .response.data.error.message */ }
    }

}

export const saveThemesTutor = async ( { commit }, theme ) => {
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.post(`/api/tutor/temas`, { tema: theme.name } )

        if (data.ok) {
            commit('setThemes', data.temas)
            return { ok: true, message: data.message, themes: data.tema }
        } else {
            return { ok: false, message: data.message }
        }

    } catch (error) {
        return { ok: false, message: error/* .response.data.error.message */ }
    }

}