
export default {

    name: 'auth',
    component: () => import(/* webpackChunkName: "Auth Layout" */ '@/modules/auth/layouts/AuthLayout.vue'),
    children: [
        {
            path: '',
            name: 'auth.login',
            component: () => import(/* webpackChunkName: "Login" */ '@/modules/auth/views/Login.vue'),
        },
        {
            path: '/register',
            name: 'auth.register',
            component: () => import(/* webpackChunkName: "register" */ '@/modules/auth/views/Register.vue'),
        },
    ]

}