export const setObservationsPerformance = ( state, observations ) => {
    state.observationsPerformance = observations
}

export const setObservationsEntrance = ( state, observations ) => {
    state.observationsEntrance = observations
}

export const setObservationsTutorship = ( state, observations ) => {
    state.observationsTutorship = observations
}

export const setObservations = ( state, observations ) => {
    state.observations = observations
}

export const setCurrentObservation = ( state, observation ) => {
    state.currentObservation = observation
}

export const setObservationsStudent = ( state, observations ) => {
    state.observationsStudent = observations
}

export const setObservationsTutor = ( state, observations ) => {
    state.observationsTutor = observations
}

export const setValuateStudent = ( state, valuateStudent ) => {
    state.valuateStudent = valuateStudent
}

export const setValuateTutor = ( state, valuateTutor ) => {
    state.valuateTutor = valuateTutor
}

export const setNumTutorshipsTutor = ( state, numTutorshipsTutor ) => {
    state.numTutorshipsTutor = numTutorshipsTutor
}

export const setNumTutorshipsStudent = ( state, numTutorshipsStudent ) => {
    state.numTutorshipsStudent = numTutorshipsStudent
}