import tutorApi from "@/api/tutorApi"

export const obtainObservationsPerformance = async ( { commit } ) => {
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.get(`/api/observaciones/rendimiento`)

        if (data.ok) {
            commit('setObservationsPerformance', data.observaciones)
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }

    } catch (error) {
        return { ok: false, message: error/* .response.data.error.message */ }
    }

}


export const obtainObservationsEntrance = async ( { commit } ) => {
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.get(`/api/observaciones/entrada`)

        if (data.ok) {
            commit('setObservationsEntrance', data.observaciones)
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }

    } catch (error) {
        return { ok: false, message: error/* .response.data.error.message */ }
    }

}

export const obtainObservationsEntranceTutor = async ( { commit } ) => {
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.get(`/api/tutor/observaciones/entrada`)

        if (data.ok) {
            commit('setObservationsEntrance', data.observaciones)
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }

    } catch (error) {
        return { ok: false, message: error/* .response.data.error.message */ }
    }

}

export const obtainObservationsUser = async ( { commit }, id ) => {
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.get(`/api/observaciones/usuario/${id}`)

        if (data.ok) {
            commit('setObservationsStudent', data.observacionesEstudiante)
            commit('setObservationsTutor', data.observacionesTutor)
            commit('setValuateStudent', data.prestigioEstudiante)
            commit('setValuateTutor', data.prestigioTutor)
            commit('setNumTutorshipsTutor', data.tutoriasTutor)
            commit('setNumTutorshipsStudent', data.tutoriasEstudiante)
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }

    } catch (error) {
        return { ok: false, message: error/* .response.data.error.message */ }
    }

}

export const deleteCommentStudent = async ( _, id ) => {
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.delete(`/api/observaciones/eliminar/estudiante/${id}`)

        if (data.ok) {
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }

    } catch (error) {
        return { ok: false, message: error/* .response.data.error.message */ }
    }

}

export const deleteCommentTutor = async ( _, id ) => {
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.delete(`/api/observaciones/eliminar/tutor/${id}`)

        if (data.ok) {
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }

    } catch (error) {
        return { ok: false, message: error/* .response.data.error.message */ }
    }

}


export const obtainObservationsTutorship = async ( { commit } ) => {
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.get(`/api/observaciones/tutoria`)

        if (data.ok) {
            commit('setObservationsTutorship', data.observaciones)
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }

    } catch (error) {
        return { ok: false, message: error/* .response.data.error.message */ }
    }

}

export const saveObservations = async ( { commit }, observation ) => {
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.post(`/api/observaciones`, { descripcion: observation.name } )

        if (data.ok) {
            commit('setObservations', data.observaciones)
            return { ok: true, message: data.message, observations: data.observaciones }
        } else {
            return { ok: false, message: data.message }
        }

    } catch (error) {
        return { ok: false, message: error/* .response.data.error.message */ }
    }

}


export const obtainObservationsCreate = async ( { commit } ) => {
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.get(`/api/observaciones`)

        if (data.ok) {
            commit('setObservations', data.observaciones)
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }

    } catch (error) {
        return { ok: false, message: error/* .response.data.error.message */ }
    }

}

export const showObservation = async ( { commit }, { idObservation } ) => {
    try {
        const { data } = await tutorApi.get(`/api/observaciones/obtener/${idObservation}`)

        if (data.ok) {
            commit('setCurrentObservation', data.observacion)
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }

    } catch (error) {
        return { ok: false, message: error }
    }
}

export const createObservation = async ( _, { form } ) => {
    const observationForm = {
        descripcion: form.name,
        tipoObservacion: form.type.code,
    }
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.post(`/api/observaciones`, observationForm)
        if (data.ok) {
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
} 

export const editObservation = async ( _, { form } ) => {
    const observationForm = {
        descripcion: form.name
    }
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.put(`/api/observaciones/${form.id}`, observationForm)
        if (data.ok) {
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
} 

export const deleteObservation = async ( _, idObservation ) => {
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.delete(`/api/observaciones/${idObservation}`)
        if (data.ok) {
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
}