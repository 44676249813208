
// export const myMutation = ( state ) => {

// }

export const toggleLoading = ( state ) => {
    state.loading = !state.loading
}

export const toggleModalRole = ( state ) => {
    state.modalRole = !state.modalRole
}