
// export default () => ({
    
// })

export default () => ({
    status: 'authenticating', // 'authenticated', 'not-authenticated', 'authenticating'
    user: {},
    roles: null,
    /* mainRole: null, */
    idToken: null,
/*     refreshToken: null, */
})
