
// export default () => ({
    
// })

export default () => ({
    tutorships: null,
    currentTutorship: null,
    tutors: null,
    students: null,
    observations: null,
    report: null,
    tutorshipMontly: null,
})
