import tutorApi from "@/api/tutorApi"


export const saveEvaluations = async ( { commit }, evaluation ) => {
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.post(`/api/evaluaciones`, { evaluacion: evaluation.name } )

        if (data.ok) {
            commit('setEvaluations', data.evaluaciones)
            return { ok: true, message: data.message, evaluations: data.evaluacion }
        } else {
            return { ok: false, message: data.message }
        }

    } catch (error) {
        return { ok: false, message: error/* .response.data.error.message */ }
    }

}


export const obtainEvaluationsCreate = async ( { commit } ) => {
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.get(`/api/evaluaciones`)

        if (data.ok) {
            commit('setEvaluations', data.evaluaciones)
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }

    } catch (error) {
        return { ok: false, message: error/* .response.data.error.message */ }
    }

}

export const showEvaluation = async ( { commit }, { idEvaluation } ) => {
    try {
        const { data } = await tutorApi.get(`/api/evaluaciones/${idEvaluation}`)

        if (data.ok) {
            commit('setCurrentEvaluation', data.evaluacion)
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }

    } catch (error) {
        return { ok: false, message: error }
    }
}

export const createEvaluation = async ( _, { form } ) => {
    const evaluationForm = {
        nombre: form.name,
        descripcion: form.description,
        tutor: form.rol,
        peso: form.weight
    }
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.post(`/api/evaluaciones`, evaluationForm)
        if (data.ok) {
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
} 

export const editEvaluation = async ( _, { form } ) => {
    const evaluationForm = {
        nombre: form.name,
        descripcion: form.description,
        tutor: form.rol,
        peso: form.weight
    }
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.put(`/api/evaluaciones/${form.id}`, evaluationForm)
        if (data.ok) {
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
} 

export const deleteEvaluation = async ( _, idEvaluation ) => {
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.delete(`/api/evaluaciones/${idEvaluation}`)
        if (data.ok) {
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
}

export const obtainEvaluationsTutor = async ( { commit } ) => {
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.get(`/api/estudiante/evaluacion/tutor`)

        if (data.ok) {
            commit('setEvaluations', data.evaluaciones)
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }

    } catch (error) {
        return { ok: false, message: error/* .response.data.error.message */ }
    }

}

export const saveEvaluationsTutor = async ( _, { form } ) => {
    let evaluationForm
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        form.forEach(async (answer) => {
            evaluationForm = {
                evaluacion: Number(answer.answer),
                encuentro_id: answer.idMeeting,
                evaluacion_id: answer.id,
                comentario: answer.comment,
                tutoria_user_id: answer.idTutorshipUser
            }

            await tutorApi.post(`/api/estudiante/evaluacion/tutor`, evaluationForm)
        });
        return { ok: true }
    } catch (error) {
        return { ok: false, message: error }
    }
}

export const saveAttendance = async ( _, { form } ) => {
    const evaluationForm = {
        encuentro_id: form.idMeeting,
        tutoria_user_id: form.idTutorshipUser
    }
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.post(`/api/estudiante/evaluacion/asistencia`, evaluationForm)
        if (data.ok) {
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
}

export const saveAutoevaluation = async ( _, { form } ) => {
    const evaluationForm = {
        encuentro_id: form.idMeeting,
        tutoria_user_id: form.idTutorshipUser,
        autoevaluacion: form.autoevaluation
    }
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.post(`/api/estudiante/evaluacion/autoevaluacion`, evaluationForm)
        if (data.ok) {
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
}

export const saveOpportunity = async ( _, { form } ) => {
    const evaluationForm = {
        encuentro_id: form.idMeeting,
        tutoria_user_id: form.idTutorshipUser,
    }
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.post(`/api/estudiante/evaluacion/oportunidad`, evaluationForm)
        if (data.ok) {
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
}

export const showEvaluationStudent = async ( { commit }, idTutorshipUser ) => {
    try {
        const { data } = await tutorApi.get(`/api/estudiante/tutorias/evaluacion/${idTutorshipUser}`)

        if (data.ok) {
            commit('setEvaluationUser', data.evaluaciones)
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }

    } catch (error) {
        return { ok: false, message: error }
    }
}

export const obtainCommentsTutor = async ( { commit }, id ) => {
    try {
        const { data } = await tutorApi.get(`/api/evaluaciones/comentarios/${id}`)

        if (data.ok) {
            commit('setComments', data.comentarios)
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }

    } catch (error) {
        return { ok: false, message: error }
    }
}

export const obtainRatingsTutor = async ( { commit }, id ) => {
    try {
        const { data } = await tutorApi.get(`/api/evaluaciones/calificaciones/${id}`)

        if (data.ok) {
            commit('setRatings', data.calificaciones)
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }

    } catch (error) {
        return { ok: false, message: error }
    }
}

export const obtainRatingsTutorship = async ( { commit }, id ) => {
    try {
        const { data } = await tutorApi.get(`/api/evaluaciones/tutoria/${id}`)

        if (data.ok) {
            commit('setRatingsTutorship', data.calificaciones)
            commit('setTotalTutorship', data.totales)
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }

    } catch (error) {
        return { ok: false, message: error }
    }
}

export const obtainValuateStudents = async ( { commit }, id ) => {
    try {
        const { data } = await tutorApi.get(`/api/evaluaciones/estudiantes/${id}`)

        if (data.ok) {
            commit('setStudents', data.estudiantes)
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }

    } catch (error) {
        return { ok: false, message: error }
    }
}

export const saveValuateStudents = async ( _, { studentsValuate, idMeeting } ) => {
    const evaluationForm = {
        encuentro_id: idMeeting,
        evaluacion: studentsValuate
    }
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.post(`/api/evaluaciones/estudiantes`, evaluationForm)
        if (data.ok) {
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
}

export const obtainStudentsEvaluate = async( { commit }, id ) => {
    try {
        const { data } = await tutorApi.post(`/api/evaluaciones/tutorias/${id}`)

        if (data.ok) {
            commit('setEvaluationUser', data.evaluaciones)
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }

    } catch (error) {
        return { ok: false, message: error }
    }
}

export const obtainValuateStudentsTutor = async ( { commit }, id ) => {
    try {
        const { data } = await tutorApi.get(`/api/tutor/evaluaciones/estudiantes/${id}`)

        if (data.ok) {
            commit('setStudents', data.estudiantes)
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }

    } catch (error) {
        return { ok: false, message: error }
    }
}

export const saveValuateStudentsTutor = async ( _, { studentsValuate, idMeeting } ) => {
    const evaluationForm = {
        encuentro_id: idMeeting,
        evaluacion: studentsValuate
    }
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.post(`/api/tutor/evaluaciones/estudiantes`, evaluationForm)
        if (data.ok) {
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
}

export const obtainStudentsEvaluateTutor = async( { commit }, id ) => {
    try {
        const { data } = await tutorApi.post(`/api/tutor/evaluaciones/tutorias/${id}`)

        if (data.ok) {
            commit('setEvaluationUser', data.evaluaciones)
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }

    } catch (error) {
        return { ok: false, message: error }
    }
}