
// export const myGetter = ( state ) => {
//   return
// }

export const currentRoles = ( state ) => {
    return state.roles
}

export const currentUser = ( state ) => {
    return state.user
}

export const getMainRole = ( state ) => {
    return state.mainRole
}

export const getStatus = ( state ) => {
    return state.status
}