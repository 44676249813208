
export default {
    
    name: 'periodos',
    component: () => import(/* webpackChunkName: "Periodos" */ '@/modules/tutorship/layouts/TutorshipLayout.vue'),
    children: [
        {
            path: '',
            name: 'period.home',
            component: () => import(/* webpackChunkName: "Periodos-listar" */ '@/modules/tutorship/views/coordinator/period/List.vue'),
        },
    ],

}