import { createRouter, createWebHashHistory } from 'vue-router'
import useFunctions from '@/composables/useFunctions'

import authRouter from '../modules/auth/router'
import tutorshipRouter from '../modules/tutorship/router/tutorship'
import usersRouter from '../modules/tutorship/router/users'
import periodsRouter from '../modules/tutorship/router/period'
import themesRouter from '../modules/tutorship/router/theme'
import evaluationsRouter from '../modules/tutorship/router/evaluation'
import observationRouter from '../modules/tutorship/router/observation'
import isAuthenticatedGuard from '../modules/auth/router/auth-guard'
import errorRouter from '../modules/tutorship/router/error'
/* 
import daybookRouter from '../modules/daybook/router'
import isAuthenticatedGuard from '../modules/auth/router/auth-guard' */

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: { name: 'auth.login' }
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/auth',
    beforeEnter: [ isAuthenticatedGuard ],
    ...authRouter
  },
  {
    path: '/tutorship',
    beforeEnter: [ isAuthenticatedGuard ],
    ...tutorshipRouter
  },
  {
    path: '/usuarios',
    beforeEnter: [ isAuthenticatedGuard ],
    ...usersRouter
  },
  {
    path: '/periodos',
    beforeEnter: [ isAuthenticatedGuard ],
    ...periodsRouter
  },
  {
    path: '/temas',
    beforeEnter: [ isAuthenticatedGuard ],
    ...themesRouter
  },
  {
    path: '/observaciones',
    beforeEnter: [ isAuthenticatedGuard ],
    ...observationRouter
  },
  {
    path: '/evaluaciones',
    beforeEnter: [ isAuthenticatedGuard ],
    ...evaluationsRouter
  },
  {
    path: '/error',
    beforeEnter: [ isAuthenticatedGuard ],
    ...errorRouter
  },
  {
      path: '/:pathMatch(.*)*',
      name: 'NotFound',
      redirect: { name: 'tutorship.error' },
  }
  /* {
    path: '/daybook',
    beforeEnter: [ isAuthenticatedGuard ],
    ...daybookRouter
  } */
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to) => {
  const { toCamelCase } = useFunctions()
  document.title = `${ process.env.VUE_APP_TITLE } -  ${ toCamelCase(to.path.split('/').filter(item => item !== '')[0]) }`
})

export default router