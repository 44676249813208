import useFunctions from "../../../../composables/useFunctions";

const { deleteDifferenceTimezone } = useFunctions()
// export const myMutation = ( state ) => {

// }

export const setTutors = ( state, tutors ) => {
    state.tutors = tutors
}

export const setObservations = ( state, observations ) => {
    state.observations = observations
}

export const setTutorshipMontly = ( state, tutorshipMontly ) => {
    state.tutorshipMontly = tutorshipMontly
}

export const setTutorships = ( state, tutorships ) => {
    const tutorshipsList = []
    if (tutorships.length > 0) {
        tutorships.forEach(tutorship => {
            let fechaInicial = deleteDifferenceTimezone(new Date(tutorship.fechaInicial))
            let fechaFinal = deleteDifferenceTimezone(new Date(tutorship.fechaFinal))
            if (!tutorship.observacion) {
                tutorshipsList.push({
                    id: tutorship.id,
                    nombre: tutorship.nombre,
                    tutor_id: tutorship.id,
                    tutor: tutorship.tutor,
                    editable: tutorship.editable,
                    estado: tutorship.estado,
                    estadoTutoriaUser: tutorship.estadoTutoriaUser,
                    formulario: tutorship.formulario,
                    periodo_id: tutorship.periodo_id,
                    recursosExternos: tutorship.recursosExternos,
                    tutoriaUserId: tutorship.tutoriaUserId,
                    userMax: tutorship.userMax,
                    userMin: tutorship.userMin,
                    fechaInicial: tutorship.fechaInicial == null ? null : fechaInicial,
                    fechaFinal: tutorship.fechaFinal == null ? null : fechaFinal,
                    horaInicial: tutorship.horaInicial,
                    horaFinal: tutorship.horaFinal,
                    linkEncuentro: tutorship.linkEncuentro,
                    encuentros: tutorship.encuentros,
                    postulacion: tutorship.postulacion ? tutorship.postulacion : null,
                    description: tutorship.descripcion
                })
            } else {
                tutorshipsList.push({
                    id: tutorship.id,
                    nombre: tutorship.nombre,
                    tutor_id: tutorship.id,
                    tutor: tutorship.tutor,
                    editable: tutorship.editable,
                    estado: tutorship.estado,
                    estadoTutoriaUser: tutorship.estadoTutoriaUser,
                    formulario: tutorship.formulario,
                    periodo_id: tutorship.periodo_id,
                    recursosExternos: tutorship.recursosExternos,
                    tutoriaUserId: tutorship.tutoriaUserId,
                    userMax: tutorship.userMax,
                    userMin: tutorship.userMin,
                    fechaInicial: tutorship.fechaInicial == null ? null : fechaInicial,
                    fechaFinal: tutorship.fechaFinal == null ? null : fechaFinal,
                    horaInicial: tutorship.horaInicial,
                    horaFinal: tutorship.horaFinal,
                    linkEncuentro: tutorship.linkEncuentro,
                    observacionId: tutorship.observacionId,
                    observacion: tutorship.observacion,
                    encuentros: tutorship.encuentros == null ? null : tutorship.encuentros,
                    postulacion: tutorship.postulacion ? tutorship.postulacion : null,
                    description: tutorship.descripcion
                })
            }
        });
    }
    state.tutorships = tutorshipsList
}

export const deleteTutorships = ( state, id ) => {
    state.tutorships = state.tutorships.filter( tutorship => tutorship.id !== id )
}

export const setCurrentTutorship = ( state, tutorship ) => {
    let fechaInicial = deleteDifferenceTimezone(new Date(tutorship.fechaInicial))
    let fechaFinal = deleteDifferenceTimezone(new Date(tutorship.fechaFinal))
    state.currentTutorship = {
        id: tutorship.id,
        nombre: tutorship.nombre,
        tutor: tutorship.tutor,
        fechaInicial: tutorship.fechaInicial == null ? null : fechaInicial,
        fechaFinal: tutorship.fechaFinal == null ? null : fechaFinal,
        horaInicial: tutorship.horaInicial,
        horaFinal: tutorship.horaFinal,
        linkEncuentro: tutorship.linkEncuentro,
        recursosExternos: tutorship.recursosExternos,
        userMax: tutorship.userMax,
        userMin: tutorship.userMin,
        editable: tutorship.editable,
        encuentros: tutorship.encuentros,
        formulario: tutorship.formulario,
        estadoTutoriaUser: tutorship.estadoTutoriaUser,
        tutoriaUserId: tutorship.tutoriaUserId,
        estado: tutorship.estado,
        postulacion: tutorship.postulacion,
        description: tutorship.descripcion,
        numHoras: tutorship.numHoras,
        temas: tutorship.temas,
        semanas: tutorship.semanas,
        horasActuales: tutorship.horasActuales,
        numAceptados: tutorship.numAceptados,
        ultimoEncuentro: tutorship.ultimoEncuentro,
        evaluacionTutor: tutorship.evaluacionTutor == 1 ? true : false,
    }
}

export const postulateTutorship = ( state, postulacion ) => {
    if (state.currentTutorship !== null && state.tutorships.length > 0) {
        state.currentTutorship.postulacion = 'en espera'
        state.tutorships.filter(tutorship => tutorship.id == postulacion.tutoria_id)[0].postulacion = 'en espera'
    } else if(state.tutorships.length > 0 && state.currentTutorship == null){
        state.tutorships.filter(tutorship => tutorship.id == postulacion.tutoria_id)[0].postulacion = 'en espera'
    } else if(state.tutorships.length == 0 && state.currentTutorship !== null) {
        state.currentTutorship.postulacion = 'en espera'
    }
}

export const noPostulateTutorship = ( state, id ) => {
    if (state.currentTutorship !== null && state.tutorships.length > 0) {
        state.currentTutorship.postulacion = 'sin postulacion'
        state.tutorships.filter(tutorship => tutorship.id == id)[0].postulacion = 'sin postulacion'
    } else if(state.tutorships.length > 0 && state.currentTutorship == null){
        state.tutorships.filter(tutorship => tutorship.id == id)[0].postulacion = 'sin postulacion'
    } else if(state.tutorships.length == 0 && state.currentTutorship !== null) {
        state.currentTutorship.postulacion = 'sin postulacion'
    }
}

export const addMeeting = ( state, meeting ) => {

    const temas = []

    meeting.tema.forEach(t => {
        temas.push({
            id: t.id,
            tema: t.nombre
        })
    })

    let time1 = new Date(`2023-01-01 ${meeting.horaFinal}`)
    let time2 = new Date(`2023-01-01 ${meeting.horaInicial}`)
    let dif = Number((time1.getTime() - time2.getTime())/3600000)

    state.currentTutorship.encuentros.push({
        descripcion: meeting.descripcion,
        fecha: meeting.fecha,
        horaFinal: meeting.horaFinal+":00",
        horaInicial: meeting.horaInicial+":00",
        horaActual: dif,
        id: meeting.id,
        recursos: meeting.recursos,
        tema: temas
    })

    state.currentTutorship.horasActuales += dif
}

export const editMeeting = ( state, meeting ) => {

    const temas = []

    meeting.tema.forEach(t => {
        temas.push({
            id: t.id,
            tema: t.nombre
        })
    })

    let time1 = new Date(`2023-01-01 ${meeting.horaFinal}`)
    let time2 = new Date(`2023-01-01 ${meeting.horaInicial}`)
    let dif = Number((time1.getTime() - time2.getTime())/3600000)

    state.currentTutorship.horasActuales -= state.currentTutorship.encuentros.filter(m => m.id == meeting.id)[0].horaActual
    state.currentTutorship.horasActuales += dif

    state.currentTutorship.encuentros.filter(m => m.id == meeting.id)[0].descripcion = meeting.descripcion
    state.currentTutorship.encuentros.filter(m => m.id == meeting.id)[0].fecha = meeting.fecha
    state.currentTutorship.encuentros.filter(m => m.id == meeting.id)[0].horaFinal = meeting.horaFinal+":00"
    state.currentTutorship.encuentros.filter(m => m.id == meeting.id)[0].horaInicial = meeting.horaInicial+":00"
    state.currentTutorship.encuentros.filter(m => m.id == meeting.id)[0].tema = temas
    state.currentTutorship.encuentros.filter(m => m.id == meeting.id)[0].recursos = meeting.recursos
    state.currentTutorship.encuentros.filter(m => m.id == meeting.id)[0].horaActual = dif
}

export const copyMeetings = ( state, { id, meeting } ) => {
    state.currentTutorship.encuentros = [...state.currentTutorship.encuentros, {
        descripcion: state.currentTutorship.encuentros.filter(e => e.id == id)[0].descripcion,
        fecha: state.currentTutorship.encuentros.filter(e => e.id == id)[0].fecha,
        horaFinal: state.currentTutorship.encuentros.filter(e => e.id == id)[0].horaFinal,
        horaInicial: state.currentTutorship.encuentros.filter(e => e.id == id)[0].horaInicial,
        id: meeting.id,
        tema: state.currentTutorship.encuentros.filter(e => e.id == id)[0].tema,
    }]
}

export const deleteMeetings = ( state, id ) => {
    state.currentTutorship.horasActuales -= Number(state.currentTutorship.encuentros.filter(e => e.id == id)[0].horaActual)
    state.currentTutorship.encuentros = [...state.currentTutorship.encuentros.filter(e => e.id !== id)]
}

export const approvePostulation = ( state, tutorship ) => {
    state.currentTutorship.estado = "1"
    state.currentTutorship.tutor.id = tutorship.tutor.id
    state.currentTutorship.tutor.nombre = tutorship.tutor.nombre+" "+tutorship.tutor.apellido
}

export const disapproveStudents = ( state, inscription ) => {
    if (state.students.filter(u => u.id == inscription.id)[0].estadoId == 1) {
        state.currentTutorship.numAceptados = (Number(state.currentTutorship.numAceptados)-1)
    }
    state.students.filter(u => u.id == inscription.id)[0].estadoId = "2"
    state.students.filter(u => u.id == inscription.id)[0].estado = "RECHAZADO"
}

export const editTutorshipTutor = ( state, form ) => {
    state.currentTutorship.formulario = form.formulario
    state.currentTutorship.recursosExternos = form.recursosExternos
    state.currentTutorship.linkEncuentro = form.linkEncuentro
}

export const approveInscription = ( state, inscription ) => {
    state.currentTutorship.numAceptados = (Number(state.currentTutorship.numAceptados)+1)
    state.students.filter(u => u.id == inscription.id)[0].estadoId = "1"
    state.students.filter(u => u.id == inscription.id)[0].estado = "ACEPTADO"
}

export const disapprovePostulation = ( state, postulation ) => {
    state.tutors.filter(t => t.id == postulation.id)[0].aval = "2"
}

export const setStudents = ( state, students ) => {
    state.students = students
}

export const signOnTutorship = ( state, tutoria ) => {
    state.currentTutorship.estadoTutoriaUser = "3"
    state.currentTutorship.tutoriaUserId = tutoria.id
}

export const removeTutorship = ( state ) => {
    state.currentTutorship.estadoTutoriaUser = null
    state.currentTutorship.tutoriaUserId = null
}

export const startTutorship = ( state ) => {
    state.currentTutorship.estado = "6"
}

export const activateTutorship = ( state ) => {
    state.currentTutorship.estado = "1"
}

export const deactivateTutorship = ( state ) => {
    state.currentTutorship.estado = "2"
}

export const finalizeTutorship = ( state ) => {
    state.currentTutorship.estado = "5"
}

export const reviseTutorship = ( state ) => {
    state.currentTutorship.estado = "4"
}

export const requestReviewTutorship = ( state ) => {
    state.currentTutorship.estado = "7"
}

export const setReport = ( state, report) => {
    state.report = report
}