
// export const myGetter = ( state ) => {
//   return
// }

export const currentLoading = ( state ) => {
    return state.loading
}

export const currentModalRole = ( state ) => {
    return state.modalRole
}
