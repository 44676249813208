import tutorApi from "@/api/tutorApi"

export const obtainUsersCreated = async ( { commit } ) => {
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.get(`/api/usuarios`)
        if (data.ok) {
            commit('setUsers', data.usuarios)
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }

    } catch (error) {
        return { ok: false, message: error/* .response.data.error.message */ }
    }
}

export const createUser = async ( _, { form } ) => {
    const userForm = {
        nombre: form.names,
        apellido: form.lastnames,
        tipoCedula: form.typeCedula,
        cedula: form.cedula,
        email: form.email,
        telefono: form.phone,
        programa_academico_id: form.program,
        semestre: form.semester,
        genero: form.gender,
        curso: form.course,
    }

    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.post(`/api/registrar`, userForm)
        if (data.ok) {
            await tutorApi.get('/sanctum/csrf-cookie')
            const { data: dataUser } = await tutorApi.put(`/api/usuarios/estado/${data.usuario.cedula}`, { estado: form.state })

            if (dataUser.ok) {
                return { ok: true, message: data.message }
            } else {
                return { ok: false, message: dataUser.message }
            }

        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
} 

export const editUser = async ( _, { form, cedula } ) => {
    const userForm = {
        nombre: form.names,
        apellido: form.lastnames,
        tipoCedula: form.typeCedula,
        email: form.email,
        telefono: form.phone,
        programa_academico_id: form.program,
        semestre: form.semester,
        genero: form.gender,
        curso: form.course,
        estado: form.state
    }

    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.put(`/api/usuarios/${cedula}`, userForm)

        if (data.ok) {
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }

    } catch (error) {
        return { ok: false, message: error }
    }
} 

export const editProfileStudent = async ( { commit }, { form, id } ) => {
    const userForm = {
        nombre: form.names,
        apellido: form.lastnames,
        tipoCedula: form.typeCedula,
        email: form.email,
        telefono: form.phone,
        programa_academico_id: form.program,
        semestre: form.semester,
        genero: form.gender,
        curso: form.course,
    }

    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.put(`/api/estudiante/perfil/${id}`, userForm)

        if (data.ok) {
            commit('auth/editCurrentUser', data.usuario, { root: true })
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }

    } catch (error) {
        return { ok: false, message: error }
    }
} 

export const editProfileTutor = async ( { commit }, { form, id } ) => {
    const userForm = {
        nombre: form.names,
        apellido: form.lastnames,
        tipoCedula: form.typeCedula,
        email: form.email,
        telefono: form.phone,
        programa_academico_id: form.program,
        semestre: form.semester,
        genero: form.gender,
        curso: form.course,
    }

    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.put(`/api/tutor/perfil/${id}`, userForm)

        if (data.ok) {
            commit('auth/editCurrentUser', data.usuario, { root: true })
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }

    } catch (error) {
        return { ok: false, message: error }
    }
} 

export const editProfileCoordinator = async ( { commit }, { form, id } ) => {
    const userForm = {
        nombre: form.names,
        apellido: form.lastnames,
        tipoCedula: form.typeCedula,
        email: form.email,
        telefono: form.phone,
        programa_academico_id: form.program,
        semestre: form.semester,
        genero: form.gender,
        curso: form.course,
    }

    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.put(`/api/coordinador/perfil/${id}`, userForm)

        if (data.ok) {
            commit('auth/editCurrentUser', data.usuario, { root: true })
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }

    } catch (error) {
        return { ok: false, message: error }
    }
} 

export const activeUser = async ( _, { form } ) => {

    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.put(`/api/usuarios/estado/${form.cedula}`, { estado: true })
        if (data.ok) {
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
} 

export const disableUser = async ( _, { form } ) => {

    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.put(`/api/usuarios/estado/${form.cedula}`, { estado: false })
        if (data.ok) {
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
} 

export const showUser = async ( { commit }, { idUser } ) => {
    try {
        const { data } = await tutorApi.get(`/api/usuarios/${idUser}`)

        if (data.ok) {
            commit('setCurrentUser', data["usuario"])
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
}

export const showUserTutor = async ( { commit } ) => {
    try {
        const { data } = await tutorApi.post(`/api/tutor/perfil`)

        if (data.ok) {
            commit('setCurrentUser', data["usuario"])
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
}

export const showUserStudent = async ( { commit } ) => {
    try {
        const { data } = await tutorApi.post(`/api/estudiante/perfil`)

        if (data.ok) {
            commit('setCurrentUser', data["usuario"])
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
}

export const resetPassword = async ( _, { form } ) => {

    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.put(`/api/usuarios/restablecer/clave/${form.cedula}`)
        if (data.ok) {
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
} 

export const assignTutorRole = async ( _, { form } ) => {

    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.put(`/api/usuarios/asignar/tutor/${form.cedula}`)
        if (data.ok) {
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
} 

export const assignCoordinatorRole = async ( _, { form } ) => {

    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.put(`/api/usuarios/asignar/coordinador/${form.cedula}`)
        if (data.ok) {
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
} 

export const removeTutorRole = async ( _, { form } ) => {

    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.put(`/api/usuarios/remover/tutor/${form.cedula}`)
        if (data.ok) {
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
}