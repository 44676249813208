export default () => ({
    observationsPerformance: null,
    
    observationsEntrance: null,

    observationsTutorship: null,

    observations: null,
    currentObservation: null,
    observationsStudent: null,
    observationsTutor: null,

    valuateStudent : null,
    valuateTutor : null,
    numTutorshipsTutor : null,
    numTutorshipsStudent : null,
})
