<template>
  <div>
    <Loader v-show="isLoading" />
    <!-- <ModalRole /> -->
    <router-view v-slot="{ Component }">
        <transition name="fade" mode="out-in">
            <component :is="Component" />
        </transition>
    </router-view>
  </div>
</template>

<style lang="scss">
    * {
        margin: 0;
        padding: 0;
        font-family: 'Poppins', sans-serif;
    }

    .fade-enter-active,
    .fade-leave-active {
    transition: opacity 0.5s ease;
    }


    .fade-enter-from,
    .fade-leave-to {
    opacity: 0;
    }
</style>

<script>
import { computed, defineAsyncComponent } from 'vue'
import { useStore } from 'vuex'

export default {
    components: {
        Loader: defineAsyncComponent(() => import('@/components/Loader')),
        /* ModalRole: defineAsyncComponent(() => import('@/components/ModalRole')), */
    },
    setup() {

      const store = useStore()

      return {
        isLoading: computed(() => store.getters['general/currentLoading'] )
      }

    }
}
</script>