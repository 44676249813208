
export default {
    
    name: 'usuarios',
    component: () => import(/* webpackChunkName: "Usuarios" */ '@/modules/tutorship/layouts/TutorshipLayout.vue'),
    children: [
        {
            path: '',
            name: 'users.home',
            component: () => import(/* webpackChunkName: "Users-list" */ '@/modules/tutorship/views/users/List.vue'),
        },
        {
            path: 'crear',
            name: 'users.create',
            component: () => import(/* webpackChunkName: "Users-create" */ '@/modules/tutorship/views/users/Create.vue'),
        },
        {
            path: 'editar/:id',
            name: 'users.edit',
            component: () => import(/* webpackChunkName: "Users-edit" */ '@/modules/tutorship/views/users/Edit.vue'),
            props: ( route ) => {
                return {
                    id: Number(route.params.id)
                }
            }
        },
        {
            path: ':id',
            name: 'users.profile',
            component: () => import(/* webpackChunkName: "Users-profile" */ '@/modules/tutorship/views/users/Profile.vue'),
            props: ( route ) => {
                return {
                    id: Number(route.params.id)
                }
            }
        }
    ],

}