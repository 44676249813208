import axios from 'axios'
import store from '../store'
import router from '../router'
import { useToast } from 'vue-toastification'

const toast = useToast()

const tutorApi = axios.create({
    baseURL: `${process.env.VUE_APP_API_URL}`,
    withCredentials: true,
})

tutorApi.interceptors.response.use( undefined, error => {
    const idToken = localStorage.getItem('idToken')

    if (error.response.status === 401 && error.response.statusText === "Unauthorized" && idToken) {
        toast.error('Sesión caducada')
        router.push({ name: 'auth.login' })
        store.commit('auth/logout')
    }
    return Promise.reject(error)
})


export default tutorApi