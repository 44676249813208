import store from '@/store'

const isAuthenticatedGuard = async( to, from, next ) => {

    const { ok/* , message */ } = await store.dispatch('auth/checkAuthentication')

    /* if ( ok ) next({ name: 'tutorship.home' })
    else next() */

    if (ok) {
        to.name === "auth.login" ? next({ name: 'tutorship.home' }) : next()
    } else {
        to.name === "auth.login" ? next() : next({ name: 'auth.login' })
    }

    // Mirar esta autenticacion

}

export default isAuthenticatedGuard