import tutorApi from "@/api/tutorApi"
// export const myAction = async ( { commit } ) => {

// }

export const createUser = async ( _, user ) => {

    const userForm = {
        nombre: user.nombres,
        apellido: user.apellidos,
        email: user.email,
        cedula: user.cedula,
        telefono: user.telefono,
        curso: user.curso,
        semestre: user.semestre,
        genero: user.genero,
        tipoCedula: user.tipoCedula,
        programa_academico_id: user.programa,
    }

    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.post('/api/registrar',  userForm)

        if (data.ok) {
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }

    } catch (error) {
        return { ok: false, message: error/* .response.data.error.message */ }
    }
}

export const signInUser = async ( { commit } , user ) => {

    const { user: cedula, password, typeUser } = user

    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.post('/api/login', { cedula, password, typeUser })
        const { token, usuario: user } = data
        commit('loginUser', { user, token })

        return { ok: true, message: 'Usuario autenticado correctamente.' }

    } catch (error) {
        return { ok: false, message: error/* .response.data.error.message */ }
    }

}

export const setMainRole = ( { commit }, mainRole ) => {
    try {
        commit('setMainRole', { mainRole })
        return { ok: true, message: 'Rol seleccionado correctamente.' }

    } catch (error) {
        return { ok: false, message: error/* .response.data.error.message */ }
    }
}

export const checkAuthentication = async ( { commit } ) => {

    const idToken = localStorage.getItem('idToken')
    if (!idToken) {
        commit('logout')
        return { ok: false, message: 'No hay token' }
    }

    tutorApi.defaults.headers.common["Authorization"] = "Bearer " + idToken

    /* const mainRole = localStorage.getItem('mainRole')
    if (!mainRole) {
        commit('logout')
        return { ok: false, message: 'No existe el rol' }
    } */

    try {
        
        const { data } = await tutorApi.post('api/buscarPorToken')
        const { usuario: user, token } = data

        commit('loginUser', { user, token })
        /* commit('setMainRole', { mainRole } ) */

        return { ok: true }

    } catch (error) {
        commit('logout')
        return { ok: false, message: error/* .response.data.error.message */ }
    }

}

export const changePassword = async ( _, { form }) => {
    const changePasswordForm = {
        currentPass: form.currentPass,
        password: form.password,
        password_confirmation: form.repeatPassword,
    }
    try {
        await tutorApi.get('/sanctum/csrf-cookie')
        const { data } = await tutorApi.post(`/api/clave`, changePasswordForm)
        if (data.ok) {
            return { ok: true, message: data.message }
        } else {
            return { ok: false, message: data.message }
        }
    } catch (error) {
        return { ok: false, message: error }
    }
}