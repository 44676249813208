
const useFunctions = () => {

    return {
        toCamelCase: (str) => {
            str = str.replace(/\w+/g, function(w){return w[0].toUpperCase() + w.slice(1).toLowerCase();});
            return str;
        },
        addDays: (date, days) => {
            let result = new Date(date)
            result.setDate(result.getDate() + days)
            return result
        },
        deleteDifferenceTimezone: (date) => {
            return new Date(date.setMinutes(date.getMinutes() + date.getTimezoneOffset()))
        }
    }

}

export default useFunctions