export const getEvaluations = ( state ) => {
    return state.evaluations
}

export const getCurrentEvaluation = ( state ) => {
    return state.currentEvaluation
}

export const getEvaluationUser = ( state ) => {
    return state.evaluationStudent
}

export const getComments = ( state ) => {
    return state.comments
}

export const getRatings = ( state ) => {
    return state.ratings
}

export const getRatingsTutorship = ( state ) => {
    return state.ratingsTutorship
}

export const getTotalTutorship = ( state ) => {
    return state.totalTutorship
}

export const getStudents = ( state ) => {
    return state.students
}